<template>
  <div :class="$vuetify.breakpoint.smAndDown ? 'pt-0' : ''">
    <v-layout justify-center v-if="!vehiculo">
      <v-progress-circular
        indeterminate
        color="info"
        class="mt-12"
      ></v-progress-circular>
    </v-layout>
    <div v-else>
      <h1 v-if="$vuetify.breakpoint.mdAndUp" class="mx-3">
        {{ vehiculo.datos.Marca }} {{ vehiculo.datos.Modelo }}
        {{ vehiculo.datos.Version }}
        {{ vehiculo.datos.Ano }}
      </h1>
      <h2 v-else class="mx-3">
        {{ vehiculo.datos.Marca }} {{ vehiculo.datos.Modelo }}
        {{ vehiculo.datos.Version }}
        {{ vehiculo.datos.Ano }}
      </h2>

      <div v-if="$vuetify.breakpoint.mdAndUp" class="d-flex flex-row">
        <div>
          <images-vehicle :vehiculo="vehiculo" />
        </div>
        <div>
          <vehiculo-info :vehiculo="vehiculo" />
        </div>
        <v-btn fab fixed bottom left color="info" @click="goBack">
          <v-icon large color="white">mdi-arrow-left-circle-outline</v-icon>
        </v-btn>
      </div>
      <div v-else>
        <v-col cols="12" class="pt-0">
          <slider-cars
            style="max-width: 400px"
            :item="vehiculo"
            size-vehicle="width: 400"
            size-item="width: 140px"
            size-image="140px"
          ></slider-cars>
        </v-col>
        <v-col
          cols="12"
          :class="!$vuetify.breakpoint.mdAndUp ? 'pt-0' : ''"
          class="fill-height"
        >
          <vehiculo-info :vehiculo="vehiculo" />
          <v-btn fab fixed bottom left color="info" @click="goBack">
            <v-icon large color="white">mdi-arrow-left-circle-outline</v-icon>
          </v-btn>
        </v-col>
      </div>
    </div>
  </div>
</template>

<script>
import {existFoto} from '@/utils/cliente';
import ImagesVehicle from '@/components/vehiculo/imagesVehicle';
import VehiculoInfo from '@/components/vehiculo/vehiculoInfo';
import SliderCars from '@/components/sliderCars';
import {mapActions, mapState} from 'vuex';

export default {
  name: 'vehiculoDetalle',
  components: {VehiculoInfo, ImagesVehicle, SliderCars},
  data: () => ({
    vehiculo: null,
  }),
  computed: {
    ...mapState(['stock2', 'stockGeneral']),
  },
  methods: {
    ...mapActions(['FIND_AIRTABLE']),
    existFoto,
    goBack() {
      this.$router.back();
    },
    message(val) {
      let item = `https://wa.me/56944436444?text=Hola%20Petersen,%20vi%20en%20su%20web%20este%20${
        val.datos.titulo.split('|')[0]
      }%20y%20me%20gustaría%20tener%20mas%20información`;
      window.open(item, '_blank');
    },
  },
  mounted() {
    const {vehiculoId} = this.$route.params;
    const {stock2, stock} = this;

    if (!stock2 || !stock2.length || !stock || !stock.length) {
      this.FIND_AIRTABLE();
      return;
    }

    this.vehiculo = stock2.find(
      (item) => item.fields['GENIO ID'].toString() === vehiculoId.toString()
    );
  },
  watch: {
    stockGeneral(val) {
      const {vehiculo} = this;

      if (!vehiculo) {
        const {vehiculoId} = this.$route.params;
        this.vehiculo = val.find(
          (item) => item.id.toString() === vehiculoId.toString()
        );
      }
    },
  },
};
</script>
